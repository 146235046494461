$title-color: #043d60;
$text-color: #646d78;
$danger-btn-color: #e02020;
$default-btn-color: #e6ecf0;

// Start - Ant CSS Reset

.ant-btn-link:hover,
.ant-btn-link:focus {
  color: $title-color;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}
// End - Ant CSS Reset

// Start - Estrutura do layout 
.ant-page-header {
  width: 100%;
  position: fixed;
  padding: 0;
  z-index: 9;

  .ant-page-header-back-button {
    padding: 20px !important;
  }
}
.app-wrapper {
  padding-top: 65px;
}
// End - Estrutura do layout 

h1,
h2,
h3,
h4,
h5 {
  color: $title-color;
}

.space-30 {
  height: 30px;
}
.ant-alert-warning {
  background-color: #fffaed;
  border-radius: 5px;
  border: none;
  .ant-alert-message {
    color: #8a6d3b;
  }
  
}

.ant-modal-confirm-body {
  .anticon {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 42px;
    margin: 30px auto;
  }
  .ant-modal-confirm-title {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 18px;
    color: #656D78;
  }
  .ant-modal-confirm-content {
    text-align: center;
    margin: 15px 0 !important;
    font-size: 16px;
    font-weight: 700;
    color: #6c737e;
  }
}
.ant-modal-confirm-btns {
  width: 100%;
  .ant-btn {
    margin: 15px 0 !important;
    width: 100%;
    border-radius: 60px;
    height: 60px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;

    background-color: #043d60;
    padding: 15px 30px 15px 30px;
    min-width: 130px;
    min-height: 50px;
    border: 1px solid rgba(4, 61, 96, 0.2);
  }
  .ant-btn-primary {
    color: #FFF;
  }
}
p,
label {
  color: $text-color;
}
.border-distance {
  margin: 0 20px 0 20px;
}
.ant-card {
  border-radius: 8px;
}
.custom-card {
  margin: 20px 20px 0 20px !important;
}
.btn-container {
  text-align: center;
  margin-top: -25px;
}
.bottom-btns-action {
  text-align: center;
  padding: 30px;
  width: 100%;

  button {
    margin: 0 10px;
  }
}
.ant-radio-button-wrapper-disabled {
    opacity: .3;
}
.ant-btn-default:hover, .ant-btn-default:focus {
    background-color: #dbe4ea;
    border: 1px solid rgba(4, 61, 96, 0.2);
}
.checkIcon {
  background-color: #6dd400 !important;
}
.errorIcon {
  background-color: #e02020 !important;
}
.exclamationIcon {
  background-color: #F7B500 !important;
}
.custom-icon-card {
  border: 7px solid #f4f5f8;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  margin: 0 auto;
  color: #fff;
  padding: 12px;
  font-size: 32px;
}

.custom-icon-card-container {
  margin-top: -10px;
  text-align: center;
  position: absolute;
  z-index: 2;
  width: 100%;
}

.ant-card-body {
  align-items: center;
}
.ant-input {
  background: #ffffff !important;
  border: 1px solid #ccd1d9 !important;
  border-radius: 30px !important;
  color: #434a54 !important;
  padding: 10px 20px !important;
  font-size: 18px;
  font-weight: 700;
}
form {
  .ant-form-item-label > label {
    line-height: 19px;
    font-weight: 700;
    font-size: 14px;
    color: #646d78;
  }
}
.logo {
  h1 {
    font-size: 28px;
    font-weight: 700;
    line-height: 38px;
    text-align: center;
  }
  span {
    color: #68879c;
    font-size: 1rem;
    margin-top: 15px;
  }
}
.search-input {
  padding: 5px 20px !important;

  input.ant-input {
    border: none !important;
    padding-left: 15px !important;
    font-size: 14px !important;
  }
}
.header-admin {
  span.ant-page-header-heading-title {
    font-size: 16px;
  }
}
.breadcrumb {
  font-size: 0.875rem;
}
.booking-event-title {
  padding: 30px 30px 10px;
  h1 {
    margin-bottom: 10px;
    font-weight: bold;
    line-height: 33px;
    text-align: left;
    font-size: 22px;
    color: #043d60;
  }
  p {
    line-height: 19px;
    text-align: left;
    font-size: 16px;
    margin: 0 0 5px;
    color: #646d78;
  }
}
.menu-wrapper {
  padding: 20px;
  .custom-menu-btn {
    width: 100%;
    background: #ffffff;
    border-radius: 8px;
    border: none;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 2px;
    box-shadow: none;

    &:hover {
      box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.02);
    }

    span {
      color: #043d60;
      font-size: 1rem;
      font-weight: bold;

      &.anticon {
        margin-right: 10px;
        font-size: 24px;

        &:last-child {
          font-size: initial;
          color: #cdd1d8;
        }
      }

      &:nth-last-child(2n) {
        width: 100%;
        text-align: left;
      }
    }
  }
}
.card-list-content {
  line-height: 22px;
  font-size: 1rem;
  color: #043d60;
  width: 100%;

  span {
    text-align: left;
  }
}
.card-schedule-infos {
  span {
    display: block;
  }
  span.date {
    margin-bottom: 0;
    font-weight: bold;
    font-size: 16px;
    color: #043d60;
  }
  span.seats {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    color: #646d78;
    margin-bottom: 5px;
  }
  span.brothers-seats,
  span.sisters-seats,
  span.musician-seats {
    width: 33%;
    float: left;
  }
}
.anticon-right {
  color: #cdd1d8;
}
.page-action-btns {
  text-align: center;

  .ant-btn {
    min-width: 200px;
    margin-bottom: 15px;
    height: 45px;
  }
}
.home-body {
  .ant-btn {
    border-radius: 32px;
    font-weight: bold;
    font-size: 1rem;
    min-width: 190px;
  }
  .ant-btn-default {
    background-color: #ffffff;
    box-shadow: 0px 8px 15px 4px rgba(0, 0, 0, 0.04);
    color: #043d60;
    margin-top: 15px;
    line-height: 14px;
    border: none;

    &:hover {
      background: rgba(255, 255, 255, 0.8);
    }
  }
  .adm-btn {
    margin-top: 30px;
    background: rgba(255, 255, 255, 0.1);
    padding: 12px 35px;
    border: 2px solid rgba(255, 255, 255, 0.5);
    span {
      color: #ffffff;
    }
    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}
.home-footer {
  p.app-version {
    font-weight: 700;
    font-size: 12px;
    color: #68879c;
  }
}

.ant-btn-default {
  background-color: $default-btn-color;
  padding: 15px 30px 15px 30px;
  min-width: 130px;
  min-height: 50px;
  border: 1px solid rgba(4, 61, 96, 0.2);

  span {
    font-weight: bold;
    color: $title-color;
  }
}

.ant-btn-primary {
  background-color: $title-color;
  padding: 15px 30px 15px 30px;
  min-height: 50px;
  border: none;

  span {
    font-weight: bold;
  }
  &:hover, &:focus {
    background-color: $title-color;
  }
}

.ant-btn-dangerous {
  background-color: $danger-btn-color !important;
}

.list-btn-link {
  border-bottom: 1px solid #e4e8ed;
  padding: 10px 0;

  .ant-btn-block {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
  }

  span {
    font-size: 1rem;
  }
}

.ant-btn-link {
  text-align: left;
  color: #434a54;
}

.ant-dropdown-trigger,
.ant-dropdown-link,
.ant-dropdown-open {
  display: inline-block;
  margin: 20px 0 15px 15px;
}

.place-apresentation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  margin-bottom: 55px;

  h1 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  h2 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0;
  }
  p {
    margin-bottom: 0;
    font-weight: 700;
    line-height: 22px;
    font-size: 18px;
  }
}

.custom-radio-button {
  .ant-radio-button-wrapper {
    background-color: #e6ecf0;
    border: none;
    border-radius: 20px !important;
    font-weight: bold;
    font-size: 0.8rem;
    color: #043d60;
    min-width: 80px;
    height: 40px;
    padding-top: 5px;
    margin-right: 5px;

    &:not(:first-child)::before {
      display: none;
    }
    &:first-child {
        border: none;
    }
  }
  .ant-radio-button-wrapper-checked {
    background-color: #043d60;
    border-color: #043d60;
    color: #ffffff;

    &:not(.ant-radio-button-wrapper-disabled):hover {
      border-color: #043d60;
      color: #ffffff;
    }
  }
}
.btn-area {
  .ant-btn.btn-check-in, .ant-btn.btn-check-out {
    width: 65%;
  }
  .ant-btn.btn-check-out {
    margin-top: 15px;
  }
}
.is-valid-container{
  .custom-icon-card-container {
    position: relative;
  }
}
.loading-container {
  padding: 20px;
}
.login-form {
  
  margin: 0 auto;
  .ant-input {
    border: none !important;

    &::-webkit-input-placeholder { /* Edge */
      color: #AAB2BD;
    }
    
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #AAB2BD;
    }
    
    &::placeholder {
      color: #AAB2BD;
    }
  }
  .ant-input-affix-wrapper {
    border-radius: 50px;
    border: 1px solid #ccd1d9 !important;
  }
  .ant-input-prefix {
    margin-right: 4px;
    margin-left: 10px;
    font-size: 22px;
    color: #ccd1d8;
  }
}
@media only screen and (min-width: 600px) {
  .custom-icon-card-container {
    position: relative;
  }
  .health-alert {
    background-color: #fffaed;
    box-shadow: none;
    margin-top: 15px;
  }
  .btn-area {
    .ant-btn {
      margin: 15px;
    }
  }
  .app-wrapper {
    max-width: 980px;
    margin: 100px auto 30px;
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 30px;
    min-height: 75vh;

    .booking-button-container {
      position: relative;
      box-shadow: none;
      background: none;
    }

    .list-btn-link {
      border: 1px solid #f0f0f0;
      background: #ffffff;
      border-radius: 8px;
      margin-bottom: 5px;
      margin-top: 5px;

      &:hover {
        background: #f0f0f0;
        cursor: pointer;
      }
    }
  }
  .ant-page-header {
    top: 0;
  }
  .search-input {
    padding: 5px 20px !important;
    border-radius: 80px;
    margin-bottom: 30px;
    border: 1px solid #e4e8ec !important;
    margin-top: 15px;
  }
  .menu-wrapper {
    .custom-menu-btn {
      border: 1px solid #f0f0f0;
      margin-bottom: 10px;

      &:hover {
        background: #f0f0f0;
        cursor: pointer;
      }
    }
  }
  .ant-btn-primary {
    &:hover {
      opacity: .9;
    } 
  }
  .ant-btn.btn-check-in, .ant-btn.btn-check-out {
    max-width: 45%;
  }
  .card-control-btn {
    &:last-child {
      text-align: right;
    }
  }
  .login-form {
    width: 40%;
  }
}
.btn-goback-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

h1.booking-valid {
  color: #6DD400;
  font-weight: 700;
}

h1.booking-warning {
  color: #F7B500;
  font-size: 26px;
  font-weight: 700;
}
h2.booking-code {
  text-transform: uppercase;
  font-weight: 700;
}
span.checkin-hour {
  font-size: 18px;
  color: #656D78;
}
span.seat-type {
  font-size: 16px;
  color: #656D78;
  font-weight: 700;
}
span.booking-code-title {
  font-weight: 14px;
  color: #656D78;
}