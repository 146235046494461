.card-canceled {
    margin-top: 60px;

    .custom-icon-card-container {
        margin-top: 0;
    }

    .custom-icon-card {
        margin-top: -30px;
    }
    
    .ant-card-body {
        padding: 30px;

        h1 {
            font-weight: bold;
            font-size: 26px;
            line-height: 35px;
            text-align: center;
            color: #043D60;
            margin: 60px 0 15px;
        }
        p {
            margin-bottom: 60px;
            font-size: 1.125rem;
            line-height: 1.5rem;
            text-align: center;
            color: #646D78;
        }

    }
    
}