.screen {
    width: 100vw;
    height: 100vh;
    background-color: #043D60;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.home-header {
    height: 20%;
    // background-color: red;
}

.home-body {
    height: 30%;
    // background-color: green;
}

.screen h1 {
    color: #FFF;
}

.opct {
    opacity: .3;
}

.home-header, .home-body, .home-body div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
}

.app-version {
    margin-top: 5rem;
}

.home-header h1 {
    margin-bottom: 0;
}

.screen {
    button {
        min-width: 180px;
    }
}


.home-notice {
    // margin: 0 auto;
    padding: 30px;
    width: 50%;
}
.home-notice-container {
    background-color: #FFFFFF;
    padding: 30px;
    border-radius: 8px;
}
.home-notice-container  h1 {
    color: #646d78 !important;
    margin-top: 0;
    font-size: 18px;
    font-weight: 700;
}
.home-notice-container  p {
    font-size: 16px;
    line-height: 20px;
    text-align: left;
}
.home-notice-container  p:last-child {
    margin-bottom: 0;
}
@media only screen and (max-width: 600px) {
    .home-notice {
        width: 100%;
    }
    .home-notice-container h1,
    .home-notice-container p {
        text-align: center;
    }
    .screen {
        height: 100%;
    }
    .home-header {
        margin-top: 30px;
    }
}