
.booking-card-content {
    h1 {
        margin-bottom: 20px;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        text-align: left;
        color: #043D60;
    }
    span.cpf-alert {
        display: block;
        width: 100%;
        padding: 10px;
        background-color: #e5f5ff;
        border-radius: 4px;
        margin-bottom: 30px;
        color: #043e60;
        text-align: left;
    }
    .custom-radio-button {
        .ant-radio-button-wrapper {
            margin-bottom: 10px;
            min-width: 140px;
        }
    }
    .church-select {
        text-align: left;
    }
    
}