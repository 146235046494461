.obs-cancellation {
    text-align: left;

    h3 {
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        color: #043D60;
    }
    
    ul {
        text-align: left;
        padding: 0 20px;
        margin: 15px 0;

        li {
            margin-bottom: 15px;
            font-size: 14px;
            line-height: 19px;
            color: #646D78;
        }
    }
}
.booking-info {
    margin-top: 30px;
    
    h2, p {
        margin: 0 0 15px 0;
        text-align: left;
    }
    h2 {
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: #043D60;
    }
    p {
        font-size: 14px;
        line-height: 19px;
        color: #646D78;
    }
    span.booking-code {
        text-transform: uppercase;
    }
}
