h1.booking-status {
  font-weight: bold;
  line-height: 35px;
  text-align: center;
  font-size: 26px;
  color: #6dd400;
  margin: 0 0 5px;
}
p {
  font-size: 1rem;
  line-height: 1.375rem;
  text-align: center;
  color: #646d78;
}
.bottom-btns-action.btns-action-booking-summary {
  button.ant-btn {
    margin: 5px;
  }
}
.booking-info {
  span.booking-code-label {
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.375rem;
    text-align: center;
    color: #646d78;
  }
  span.seat-category {
    font-weight: bold;
    line-height: 1.375rem;
    font-size: 1rem;
    color: #043d60;
  }
  h2.booking-code {
    font-weight: bold;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
    color: #043d60;
    margin: 15px 0 0;
    text-transform: uppercase;
  }
  h3 {
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.375rem;
    text-align: center;
    color: #646d78;
    margin: 30px 0 0;
  }
}
.booking-summary-content {
  padding-top: 30px;
}
.summary {
  h1 {
    text-align: left;
    color: #656D78;
    font-weight: 700;
    font-size: 24px;
  }
  h2,
  p {
    text-align: left;
  }
  h2 {
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 18px;
  }
  p {
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .church-address {
    margin: 0;
    padding: 20px 0 0;
  }
}
