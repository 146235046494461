.card-list-wrapper {

    .ant-card-body {
        padding: 20px;
    }

    .card-list-container {
        text-align: left;
        
        span {
            line-height: 1.5rem;
            font-size: 1rem;
            display: block;
            color: #646D78;
            width: 100%;
    
            &.date {
                font-weight: bold;
            }      
        }
        h1 {
            font-weight: bold;
            line-height: 1.5rem;
            font-size: 1.125rem;
            color: #043D60;
        }
    }
}
.booking-found {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;

    .card-list-container {
        width: 100%;
    }
}