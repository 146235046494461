span.unoccupied {
    margin: 15px 15px 0;
    line-height: 1.563rem;
    font-size: 24px;
    color: #6DD400;
}
span.not-confirmed {
    margin: 15px 15px 5px;
    font-size: 18px;
    line-height: 1.375rem;
    color: #043D60;
}
.occupation-status {
    span {
        text-align: center;
        display: block;
    }
}
strong {
    font-weight: bold;
}
.card-title {
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 1.563rem;
    color: #043D60;
    span {
        text-align: left;
    }
}
.card-seat-avaliable {
    font-size: 18px;
    line-height: 19px;
    color: #646D78;
    text-align: right;
    
    span {
        text-align: right;
    }
    strong {
        font-weight: bold;
        font-size: 18px;
        line-height: 2rem;
        text-align: right;
        color: #646D78;
    }
}
.card-control-btn {
    margin-top: 5px;
    
    &:first-child {
        padding-right: 5px;
    }
    &:last-child {
        padding-left: 5px;
    }
}
.ant-btn.btn-check-in {
    background-color: #6DD400;
    
    &:hover, &:active, &:focus {
        background-color: #64BF04;
    }
}
.ant-btn.btn-check-out {
    background-color: #E02020;
    
    &:hover, &:active, &:focus {
        background-color: #C71818 !important;
    }
}
.ant-btn.btn-check-in,
.ant-btn.btn-check-out {
    border-radius: 40px;
    width: 100%;
    height: 45px;
    border: none;

    span {
        font-weight: bold;
        line-height: 1.18rem;
        font-size: 0.875rem;
        color: #FFFFFF;
    }
}
