.custom-card {
  margin: 0 20px 0 20px !important;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  .ant-card-body {
    padding: 30px 30px 60px;
    width: 100%;

    h1.avaliable-title {
      font-weight: bold;
      line-height: 35px;
      font-size: 26px;
      color: #434a54;
      margin-top: 25px;
      margin-bottom: 5px;
    }
    span.last-update {
      line-height: 19px;
      font-size: 14px;
      color: #646d78;
    }
    .seats {
      margin: 30px 0 0;
    }
    .seat-avaliable {
      padding: 30px;

      &:first-child {
        border-right: 1px solid #e5e7ee;
      }

      h1 {
        font-weight: bold;
        line-height: 52px;
        font-size: 38px;
        margin: 0;
      }
      span {
        font-weight: bold;
        line-height: 22px;
        font-size: 16px;
        text-align: center;
        display: block;
        width: 100%;
        color: #434a54;
      }
      .ant-btn-default {
        padding: 10px 25px 10px 25px;
      }
    }
  }
}
.wrapper-avaliable-seats {
  padding-bottom: 102px;
}
.booking-button-container {
  text-align: center;
  padding: 20px;
  width: 100%;
  bottom: 0;
  position: fixed;
  background: rgba(255, 255, 255, 0.8);
  margin-bottom: 0;
  box-shadow: 0px -4px 15px 4px rgba(0, 0, 0, 0.04);
  z-index: 9;

  .booking-button {
    background-color: #043d60;
    min-height: 50px;
    font-size: 16px;
    border: none;
    padding: 13px 30px 15px 30px;
    min-width: 160px;
  }
}
h1.seat-unavailable {
  color: #e02020;
}
h1.seat-available {
  color: #6dd400;
}

.health-alert {
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 8px 15px 4px rgba(0, 0, 0, 0.04);
  padding: 20px;
  display: flex;
  align-items: center;

  span {
    float: left;
  }
  p {
    margin-bottom: 0;
    margin-left: 15px;
    text-align: left;
    float: left;
  }
  .health-alert-icon {
    margin-top: 2px;
    font-size: 36px;
    color: rgb(247, 181, 0);
  }
}
.church-address {
  padding: 30px 0 30px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;

  .address-content {
    h2 {
      line-height: 22px;
      font-weight: bold;
      font-size: 16px;
      color: #043d60;
    }
    p {
      font-weight: normal;
      line-height: 19px;
      text-align: left;
      font-size: 14px;
      margin: 0;
      color: #434a54;
    }
  }
  .church-pin {
    .open-map-btn {
      background: #ffffff;
      box-shadow: 0px 8px 15px 4px rgba(0, 0, 0, 0.04);
      color: #043d60;
      border-radius: 50px;
      padding: 18px;
      font-size: 28px;

      &:hover {
        background-color: #043d60;
        color: #ffffff;
      }
    }
  }
}
