.ant-page-header-heading-title, .ant-page-header-back-button {
    color: #FFF !important;
}

.state-list {
    list-style: none;
    padding: 0 20px;
}

.state-list li {
    border-bottom: solid 1px #E4E8ED;
    color: #434A54;
}

a {
    color: #434A54 !important;
}